import React, { useState } from 'react'
import posed from 'react-pose'
import MediaQuery from 'react-responsive'
import styles from './Header.module.scss'
import Link from '../Link/Link'
import CWL from '../CenteredWidthLimiter/CenteredWidthLimiter'
import MoreIcon from '../icons/MoreIcon'
import MailIcon from '../icons/MailIcon'
import FacebookIcon from '../icons/FacebookIcon'
import InstagramIcon from '../icons/InstagramIcon'

const NavItemsList = posed.ul({
  open: {
    x: '0%',
  },
  closed: {
    x: '100%',
  },
  static: {
    x: '0%',
    transition: { duration: 0 },
    applyAtStart: { x: '0%' },
  },
})

const Backdrop = posed.div({
  open: {
    opacity: 1,
    applyAtStart: { display: 'block' },
  },
  closed: {
    opacity: 0,
    applyAtEnd: { display: 'none' },
  },
})

export default function Header() {
  const [screenIsWide, setScreenIsWide] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const closeMenu = () => setMenuIsOpen(false)
  const toggleMenuOpen = () => setMenuIsOpen(!menuIsOpen)

  return (
    <>
      <MediaQuery minWidth="40em">
        {matches => {
          setScreenIsWide(matches ? true : false)
          return null
        }}
      </MediaQuery>
      <div className={styles.headerPlaceholder} />
      {screenIsWide ? null : (
        <Backdrop
          className={styles.backdrop}
          onClick={closeMenu}
          pose={menuIsOpen ? 'open' : 'closed'}
        />
      )}
      <header className={styles.header}>
        <CWL>
          <nav className={styles.navigation}>
            <h1>
              <Link className={styles.siteTitle} to="/" onClick={closeMenu}>
                VU PAR UN ARTISTE
              </Link>
            </h1>
            {screenIsWide ? null : (
              <button className={styles.icon} onClick={toggleMenuOpen}>
                <MoreIcon />
              </button>
            )}

            <NavItemsList
              className={styles.navItemsList}
              pose={screenIsWide ? 'static' : menuIsOpen ? 'open' : 'closed'}
            >
              <li className={styles.navItem}>
                <Link to="/contact" className={styles.icon} onClick={closeMenu}>
                  <MailIcon />
                </Link>
              </li>
              <li className={styles.navItem}>
                <Link
                  to="https://www.facebook.com/vuparunartiste/"
                  className={styles.icon}
                  onClick={closeMenu}
                >
                  <FacebookIcon />
                </Link>
              </li>
              <li className={styles.navItem}>
                <Link
                  to="https://www.instagram.com/vuparunartiste/"
                  className={styles.icon}
                  onClick={closeMenu}
                >
                  <InstagramIcon />
                </Link>
              </li>
            </NavItemsList>
          </nav>
        </CWL>
      </header>
    </>
  )
}
