// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-collection-page-template-jsx": () => import("/opt/build/repo/src/templates/CollectionPageTemplate.jsx" /* webpackChunkName: "component---src-templates-collection-page-template-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("/opt/build/repo/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-infos-jsx": () => import("/opt/build/repo/src/pages/infos.jsx" /* webpackChunkName: "component---src-pages-infos-jsx" */),
  "component---src-pages-privacy-jsx": () => import("/opt/build/repo/src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */)
}

