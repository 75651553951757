import 'typeface-montserrat'
import 'typeface-eb-garamond'
import 'destyle.css'
import './src/scss/_baseStyles.scss'

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}
