import React from 'react'

export default function MoreIcon() {
  return (
    <svg viewBox="0 0 128 128" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="moreIcon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <circle id="Oval" fill="#000000" cx="64" cy="26" r="4" />
        <circle id="Oval" fill="#000000" cx="64" cy="64" r="4" />
        <circle id="Oval" fill="#000000" cx="64" cy="102" r="4" />
      </g>
    </svg>
  )
}
