import React from 'react'
import styles from './Layout.module.scss'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import CWD from '../components/CenteredWidthLimiter/CenteredWidthLimiter'

export default function Layout({ children }) {
  return (
    <div className={styles.layout}>
      <Header />
      <main className={styles.main}>
        <CWD>{children}</CWD>
      </main>
      <Footer />
    </div>
  )
}
