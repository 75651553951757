import React from 'react'

export default function MailIcon({ className }) {
  return (
    <svg viewBox="0 0 128 128" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Artboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          id="Rectangle"
          stroke="#000000"
          strokeWidth="4"
          x="12"
          y="29"
          width="104"
          height="71"
          rx="8"
        />
        <polyline
          id="Path-5"
          stroke="#000000"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="18 34.5 64 64.5 110 34.5"
        />
      </g>
    </svg>
  )
}
