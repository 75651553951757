import React from 'react'

export default function InstagramIcon() {
  return (
    <svg viewBox="0 0 128 128" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="instagramIcon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M64.861908,12.00005 L64.8585812,12.0033755 L64.0303921,12.0033755 C50.4972554,12.0033755 48.2060227,12.0396378 41.8512306,12.3187332 C36.7422546,12.5440376 32.9429847,13.310351 29.384049,14.695381 C25.8730565,16.0646435 23.1534622,17.808965 20.4862614,20.4960468 C17.8098262,23.1534392 16.0632953,25.8691612 14.6951575,29.3544356 C13.3026154,32.9544963 12.540419,36.7250501 12.3155128,41.8208507 C12.0362173,48.1973683 12,50.4858203 12,64.0118143 C12,77.5394197 12.0362765,79.8296888 12.3154834,86.1818569 C12.5408685,91.2885096 13.3074447,95.0859941 14.692357,98.6417412 C16.068183,102.158162 17.8110137,104.874359 20.491032,107.532335 C23.1738984,110.214111 25.9118812,111.97379 29.3581226,113.305795 C32.9598554,114.697852 36.732182,115.459787 41.8306599,115.684629 C48.1809059,115.963688 50.4756045,116 64.0101307,116 C77.5446569,116 79.8393555,115.963688 86.1892922,115.684642 C91.2982682,115.459338 95.0975381,114.693024 98.6621176,113.305803 C102.113485,111.971817 104.849103,110.214391 107.528433,107.533131 C110.21387,104.845759 111.973607,102.110645 113.304842,98.6691931 C114.697385,95.0691324 115.459581,91.2985786 115.684503,86.2024196 C115.963674,79.8547954 116,77.5610618 116,64.0320675 C116,50.5044621 115.963723,48.2141931 115.684517,41.862025 C115.459131,36.7553723 114.692555,32.9578877 113.294923,29.3687545 C112.0084,25.8991637 110.276326,23.1748994 107.573129,20.4879186 C104.890263,17.8061423 102.15228,16.0464633 98.7060385,14.7144581 C95.1043057,13.322401 91.3319791,12.5604659 86.2268236,12.3353198 C80.0649643,12.0439017 77.7619879,12.0016664 64.861908,12.00005 Z"
          id="Path"
          stroke="#000000"
          strokeWidth="4"
          fillRule="nonzero"
        />
        <path
          d="M64.5,39 C50.4230017,39 39,50.4220418 39,64.5 C39,78.5791511 50.4208489,90 64.5,90 C78.5791511,90 90,78.5791511 90,64.5 C90,50.4230017 78.5779582,39 64.5,39 Z"
          id="Path"
          stroke="#000000"
          strokeWidth="4"
          fillRule="nonzero"
        />
        <path
          d="M98,35 C98,38.3142262 95.3142262,41 92,41 C88.6857738,41 86,38.3142262 86,35 C86,31.6857738 88.6857738,29 92,29 C95.3142262,29 98,31.6857738 98,35 L98,35 Z"
          id="Path"
          fill="#000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}
