import React from 'react'
import styles from './Footer.module.scss'
import Link from '../Link/Link'
import CWD from '../CenteredWidthLimiter/CenteredWidthLimiter'

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <CWD>
        <Link className={styles.link} to="/privacy">
          Confidentialité / Privacy
        </Link>
        <Link className={styles.link} to="/infos">
          Infos
        </Link>
      </CWD>
    </footer>
  )
}
